@font-face
	{
	  font-family: "Weissenhof Grotesk Regular";
	  src: url('fonts/WeissenhofGrotesk-Regular.woff2') format('woff2'), url('fonts/WeissenhofGrotesk-Regular.woff') format('woff'), url('fonts/WeissenhofGrotesk.ttf') format('truetype'), url('fonts/WeissenhofGrotesk.svg') format('svg'), url('fonts/WeissenhofGrotesk.eot') format('embedded-opentype');
	  font-weight: normal;
	  font-style: normal;
	}

@font-face
	{
	  font-family: "Weissenhof Grotesk Medium";
	  src: url('fonts/WeissenhofGrotesk-Medium.woff2') format('woff2'), url('fonts/WeissenhofGrotesk-Medium.woff') format('woff'), url('fonts/WeissenhofGrotesk-Md.ttf') format('truetype'), url('fonts/WeissenhofGrotesk-Md.svg') format('svg'), url('fonts/WeissenhofGrotesk-Md.eot') format('embedded-opentype');
	  font-weight: normal;
	  font-style: normal;
	}